<template>
  <modal
    name="annual-report-modal"
    height="auto"
    width="70%"
    @before-close="beforeClose"
  >
    <div class="annual-report-modal-container">
      <button class="close" @click="dismiss">
        <i class="fa fa-times" />
      </button>
      <img
        class="hero-background"
        src="~@/assets/annual-report-2024/yearly_bg_cover.jpg"
      />
      <img
        class="hero-image"
        src="~@/assets/annual-report-2024/bg_cover_millenials.png"
      />
      <h2>
        HIGO <br />
        DIGITAL <br />
        MANUAL <br />
        2024
      </h2>
      <p>
        Inovasi Digital Membentuk <br />
        Trend Kebiasaan Gen Z & Millennial di Indonesia
      </p>
      <div class="actions">
        <a href="/annualreport2024" class="read-more" @click="dismiss"
          >BACA SELENGKAPNYA</a
        >
        <a
          href="/annualreport2024"
          @click="openForm"
          class="download-full-report"
          >Download Full Report</a
        >
      </div>
    </div></modal
  >
</template>

<script>
export default {
  name: "AnnualReportModal",
  methods: {
    dismiss() {
      this.$modal.hide("annual-report-modal");
    },
    openForm(evt) {
      evt.preventDefault();

      this.$modal.hide("annual-report-modal");
      this.$modal.show("annual-report-form-modal");
    },
    beforeClose() {
      sessionStorage.setItem("annual-report-2024", true);
    },
  },
};
</script>

<style>
.annual-report-modal-container {
  --padding: 2rem;
  --size: calc((100% - (var(--padding) * 4)) / 2);
  --background-color: #243be1;

  height: 450px;
  background-color: var(--background-color);
  display: grid;
  grid-auto-flow: column dense;
  grid-template-columns:
    [full-start]
    var(--padding)
    [content-start] 0 [cover-start] 1fr [cover-end] 0 [title-start] 1fr [title-end] 0 [content-end] var(
      --padding
    )
    [full-end];
  grid-template-rows:
    [full-start] 0 [background-start] var(--padding)
    [cover-start] 1fr [cover-end] var(--padding)
    [background-end] 1rem
    [description-start] auto [description-end] 1rem
    [button-start] auto [button-end] 1rem
    [full-end];
}

.annual-report-modal-container > * {
  margin: 0;
  padding: 0;
}

.annual-report-modal-container img {
  width: 100%;
  height: 100%;
  place-self: center;
  object-fit: cover;
}

.annual-report-modal-container .hero-background {
  grid-column: full;
  grid-row: background;
}

.annual-report-modal-container .hero-image {
  grid-column: cover;
  grid-row: cover;
  object-fit: contain;
}

.annual-report-modal-container .close {
  grid-column-end: full;
  border: none;
  background-color: transparent;
  font-size: 1.1rem;
  color: white;
  aspect-ratio: 1 / 1;
  z-index: 1;
}

.annual-report-modal-container h2 {
  font-family: "RMConnect";
  font-size: 6vw;
  font-weight: 500;
  color: white;
  grid-column: title;
  grid-row: cover;
  justify-self: center;
  align-self: center;
}

.annual-report-modal-container p {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  color: white;
  grid-column: content;
  grid-row: description;
  justify-self: center;
  align-self: center;
  text-align: center;
  line-height: 1.4;
}

.annual-report-modal-container .actions {
  grid-column: content;
  grid-row: button;
  display: grid;
  justify-content: center;
  align-items: center;
}

.annual-report-modal-container .actions a {
  font-family: "Inter", sans-serif;
  background-color: #00e2f4;
  border: none;
  border-radius: 100px;
  padding: 15px;
  font-size: 0.85rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--background-color);
  white-space: nowrap;
}

.annual-report-modal-container .actions a.read-more {
  display: none;
}

@media only screen and (max-width: 768px) {
  .annual-report-modal-container .actions a {
    padding: 10px;
    font-size: 0.75rem;
  }

  .annual-report-modal-container p {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 768px) {
  .annual-report-modal-container {
    --padding: 4rem;

    height: 80vh;
    grid-template-columns:
      [full-start] 0 [background-start] var(--padding)
      [cover-start] var(--size) [cover-end] var(--padding)
      [background-end]
      var(--padding)
      [content-start] var(--size) [content-end] var(--padding)
      [full-end];
    grid-template-rows:
      [full-start] var(--padding)
      [content-start] 0 [title-start] auto [title-end] 0
      [description-start] auto [description-end] 1rem
      [button-start] 1fr [button-end] 0
      [content-end] var(--padding)
      [full-end];
  }

  .annual-report-modal-container .hero-background {
    grid-column: background;
    grid-row: full;
  }

  .annual-report-modal-container .hero-image {
    grid-column: cover;
    grid-row: content;
  }

  .annual-report-modal-container .close {
    grid-column-end: full;
  }

  .annual-report-modal-container h2 {
    font-family: "RMConnect";
    font-size: 2rem;
    margin-bottom: 10px;
    grid-column: content;
    grid-row: title;
  }

  .annual-report-modal-container p {
    grid-column: content;
    grid-row: description;
    text-align: left;
  }

  .annual-report-modal-container h2,
  .annual-report-modal-container p {
    justify-self: baseline;
    align-self: baseline;
  }

  .annual-report-modal-container p {
    font-family: "Inter", sans-serif;
  }

  .annual-report-modal-container .actions {
    grid-column: content;
    grid-row: button;
    justify-content: flex-start;
    align-items: baseline;
  }

  .annual-report-modal-container .actions a.read-more {
    display: block;
  }

  .annual-report-modal-container .actions a.download-full-report {
    display: none;
  }
}

/* @media only screen and (max-width: 1512px) {
  .annual-report-modal-container h2 {
    font-size: 2.3rem;
  }
} */

@media only screen and (min-width: 1512px) {
  .annual-report-modal-container h2 {
    font-family: "RMConnect";
    font-size: 3.1rem;
    margin-bottom: 10px;
    grid-column: content;
    grid-row: title;
  }
}
</style>
