<template>
  <modal
    name="annual-report-form-modal"
    height="auto"
    :width="800"
    :adaptive="true"
    :focusTrap="true"
  >
    <AnnualReportForm :is-modal="isModal" :dismiss="dismiss" />
  </modal>
</template>

<script>
import AnnualReportForm from "./AnnualReportForm";

export default {
  name: "AnnualReportFormModal",
  components: {
    AnnualReportForm,
  },
  props: {
    isModal: Boolean,
  },
  methods: {
    dismiss() {
      this.$modal.hide("annual-report-form-modal");
    },
  },
};
</script>
