<template>
  <div class="annual-report-form-container">
    <header>
      <div class="circle" />
      <div class="content">
        <p>Get Your Copy</p>
        <h4>HIGO DIGITAL MANUAL 2024</h4>
        <p>
          Inovasi Digital Membentuk Trend Kebiasaan Gen Z & Millennial di
          Indonesia
        </p>
      </div>
      <button v-if="isModal" class="dismiss" @click="dismiss">
        <i class="fa fa-times" />
      </button>
    </header>
    <form @submit="submit">
      <input
        type="text"
        autocomplete="off"
        spellcheck="true"
        autocapitalize="off"
        name="firstname"
        placeholder="First Name"
        v-model="firstname"
        :class="{ error: hasError('firstname') }"
        @input="validateForm"
      />
      <input
        type="text"
        autocomplete="off"
        spellcheck="true"
        autocapitalize="off"
        name="lastname"
        placeholder="Last Name"
        v-model="lastname"
        :class="{ error: hasError('lastname') }"
        @input="validateForm"
      />
      <input
        type="email"
        autocomplete="off"
        spellcheck="true"
        autocapitalize="off"
        name="email"
        placeholder="Email"
        v-model="email"
        :class="{ error: hasError('email') }"
        @input="validateForm"
      />
      <input
        type="text"
        autocomplete="off"
        spellcheck="true"
        autocapitalize="off"
        name="phone"
        placeholder="Phone Number"
        v-model="phone"
        :class="{ error: hasError('phone') }"
        @input="validateForm"
      />
      <input
        type="text"
        autocomplete="off"
        spellcheck="true"
        autocapitalize="off"
        name="company"
        placeholder="Company Name"
        v-model="company"
        :class="{ error: hasError('company') }"
        @input="validateForm"
      />
      <input
        type="text"
        autocomplete="off"
        spellcheck="true"
        autocapitalize="off"
        name="job"
        placeholder="Job Title"
        v-model="job"
        :class="{ error: hasError('job') }"
        @input="validateForm"
      />
      <div class="fill-width">
        <button class="button" :disabled="!validated || submitting">
          Download
        </button>
        <a
          class="whatsapp-icon"
          href="https://url.higo.id/annualreportwa2024"
          data-action="share/whatsapp/share"
        >
          <img
            src="@/assets/annual-report-2024/icon_whatsapp.png"
            alt="whatsapp-icon"
          />
        </a>
        <a class="share-button" @click="clickShare">
          <i class="fa fa-share-alt"></i>
        </a>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AnnualReportForm",
  props: {
    isModal: Boolean,
    dismiss: Function,
  },
  data() {
    return {
      errors: {},
      changed: {},
      validated: false,
      submitting: false,
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      company: "",
      job: "",
    };
  },
  methods: {
    async clickShare(evt) {
      evt.preventDefault();
      try {
        await navigator.clipboard.writeText("higo.id/annualreport2024");
        alert("Copied: higo.id/annualreport2024");
      } catch (err) {
        console.error(`failed to copy: ${err}`);
      }
    },
    validateForm(evt) {
      const errors = {};
      const changed = { ...this.changed };
      const fields = [
        "firstname",
        "lastname",
        "email",
        "phone",
        "company",
        "job",
      ];

      if (typeof evt === "object" && evt !== null && evt.target) {
        changed[evt.target.name] = true;
      }

      for (const key of fields) {
        if (changed[key] && !this.validateNonEmpty(this[key])) {
          errors[key] = "This field is required";
        }
      }
      if (
        changed.phone &&
        this.validateNonEmpty(this.phone) &&
        !this.validateNumeric(this.phone)
      ) {
        errors.phone = "Please enter a valid phone number";
      }
      if (
        changed.email &&
        this.validateNonEmpty(this.email) &&
        !this.validateEmail(this.email)
      ) {
        errors.email = "Please enter a valid email address";
      }

      this.errors = errors;
      this.changed = changed;
      this.validated =
        Object.keys(errors).length === 0 &&
        Object.keys(changed).length === fields.length;
    },
    validateNonEmpty(value) {
      return typeof value === "string" && value.trim().length > 0;
    },
    validateNumeric(value) {
      return /^\d+$/.test(value);
    },
    validateEmail(value) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value.toLowerCase()
      );
    },
    hasError(reference) {
      return this.errors.hasOwnProperty(reference);
    },
    async submit(evt) {
      evt.preventDefault();

      if (Object.values(this.errors).length > 0) return;

      try {
        this.submitting = true;

        const { firstname, lastname, email, phone, company, job } = this;
        const body = new FormData();

        body.append("first_name", firstname);
        body.append("last_name", lastname);
        body.append("company", company);
        body.append("job", job);
        body.append("email", email);
        body.append("phone", phone);
        body.append("year", "2024");

        const response = await fetch(
          "https://apiw.higo.id/yearlyreport-download",
          {
            method: "POST",
            body,
          }
        );
        const json = await response.json();
        window.location.assign(json.pdf_url);

        this.firstname = "";
        this.lastname = "";
        this.email = "";
        this.phone = "";
        this.company = "";
        this.job = "";
        this.changed = {};
        this.errors = {};
      } catch (err) {
        alert(err.message);
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style>
.annual-report-form-container {
  --background-color: #253be3;

  background-color: var(--background-color);
  display: grid;
  grid-template-columns: 2rem [content-start] calc(100% - 4rem) [content-end] 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  max-height: 100vh;
  max-height: 100svh;
  overflow-y: auto;
}

.annual-report-form-container > * {
  grid-column: content;
}

.annual-report-form-container header {
  display: grid;
  grid-template-columns: 50px auto 20px;
  grid-template-areas: "circle content dismiss";
  align-items: center;
  padding-bottom: 2rem;
}

.annual-report-form-container header .circle {
  background-color: #7f3ff4;
  border-radius: 50%;
  grid-area: circle;
  height: 50%;
  aspect-ratio: 1 / 1;
}

.annual-report-form-container header .content {
  grid-column-start: content;
  grid-row-start: 1;
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: white;
}

.annual-report-form-container header .content p {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.annual-report-form-container header .dismiss {
  grid-area: 1 / dismiss;
  align-self: baseline;
  aspect-ratio: 1 / 1;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.1rem;
}

.annual-report-form-container h4 {
  font-family: "RMConnect";
  font-size: 1.7rem;
  font-weight: 500;
}

.annual-report-form-container form {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
}

.annual-report-form-container form input {
  font-family: "Inter", sans-serif;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  padding: 2ch 1ch;
  color: white;
  outline: none;
}

.annual-report-form-container form input.error {
  font-family: "Inter", sans-serif;
  border-bottom-color: #ff5e5e;
  color: #ff5e5e;
}

.annual-report-form-container form input.error::placeholder {
  font-family: "Inter", sans-serif;
  color: #ff5e5e;
}

.annual-report-form-container form input::placeholder {
  font-family: "Inter", sans-serif;
  color: white;
}

.annual-report-form-container form button {
  font-family: "Inter", sans-serif;
  background-color: #0ce8f6;
  border: none;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 1ch 2.5ch;
  color: #243be1;
}

.annual-report-form-container form button:disabled {
  opacity: 0.5;
}

.annual-report-form-container .fill-width {
  grid-column: -1 / 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.annual-report-form-container .whatsapp-icon img {
  width: 30px;
  height: 30px;
  margin: 20px;
}

.annual-report-form-container button {
  cursor: pointer;
}

.annual-report-form-container .whatsapp-icon:active {
  opacity: 0.5;
}

.annual-report-form-container .share-button {
  font-size: 1.4rem;
}

.annual-report-form-container .share-button:active {
  opacity: 0.5;
}

.fa-share-alt:before {
  color: #ffffff;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .annual-report-form-container header .circle {
    height: 100%;
  }
  .annual-report-form-container {
    overflow-y: hidden;
  }

  .annual-report-form-container form button {
    border: 1px solid white;
    background-color: transparent;
    color: white;
  }
}
</style>
