<template>
  <div id="container">
  <vue-element-loading :active="loading" is-full-screen>
      <img src="@/assets/gif/Motion-Logo-.gif" style="width: 50%; max-width: 250px;">
  </vue-element-loading>
  <!-- SEO H1 => H2 -->
    <h2 v-html="message" style="display: none"></h2>

    <h2 id="title" v-if="lang=== 'id'">Pilih Kebutuhanmu</h2>
    <h2 id="title" style="font-size: 32px" v-else>How can we help you?</h2>
    <div id="select-content">
      <div id="select-container" v-if="lang === 'id'">
        <div id="selected" :style="{ width: widthSelected + '%', left: leftSelected + '%' }"></div>
        <div id="iklan-content" class="select" @click.prevent="switchContent('iklan')"><h4>Iklan</h4></div>
        <div id="partner-content" class="select" @click.prevent="switchContent('partner')"><h4>Lokasi Partner</h4></div>
        <div id="event-content" class="select" @click.prevent="switchContent('event')"><h4>Event</h4></div>
      </div>

      <div id="select-container" v-else>
        <div id="selected" :style="{ width: widthSelected + '%', left: leftSelected + '%' }"></div>
        <div id="iklan-content" class="select" @click.prevent="switchContent('iklan')"><h4>Advertisement</h4></div>
        <div id="partner-content" class="select" @click.prevent="switchContent('partner')"><h4>Location Partner</h4></div>
        <div id="event-content" class="select" @click.prevent="switchContent('event')"><h4>Event</h4></div>
      </div>

    </div>
    <div id="main-content">
      <img @load="imgLoad" src="@/assets/homepage/Group-134-min.png" id="line-2" alt="">

        <div id="wrapper">

        <transition
  -        name="slide-left"
          mode="out-in">
          <img v-if="content === 'iklan'" id="img-phone" src="@/assets/homepage/21 fact-min.png" alt="">
        </transition>

        <transition
  -        name="slide-left"
          mode="out-in">
          <img v-if="content === 'partner'" id="img-phone" src="@/assets/homepage/teras-min.png" alt="">
          <!-- <div class="text-wrapper"></div> -->
        </transition>

        <transition
  -        name="slide-left"
          mode="out-in">
          <img v-if="content === 'event'" id="img-phone" src="@/assets/homepage/playfest-min.png" alt="">
          <!-- <div class="text-wrapper"></div> -->
        </transition>

        <transition
          name="slide-left"
          mode="out-in">
            <div class="text-wrapper"  v-if="content === 'iklan' && lang === 'id'">
              <h4 id="text-title">Pastikan iklanmu dilihat tanpa bisa dilewatkan</h4>
              <ul id="text-list">
                <li class="list-item">Tingkatkan brand awareness</li>
                <li class="list-item">Raih impression tinggi</li>
                <li class="list-item">Dapatkan leads terbaik</li>
                <li class="list-item">Audien yang tersegmentasi</li>
              </ul>
            </div>

        </transition>

        <transition
          name="slide-left"
          mode="out-in">
        <div class="text-wrapper"  v-if="content === 'iklan' && lang === 'en'">
              <h4 id="text-title">Make sure your Ads will be seen by the right audience</h4>
              <ul id="text-list">
                <li class="list-item">Increase your brand awareness</li>
                <li class="list-item">Get high impressions with higher CTR</li>
                <li class="list-item">Collect the best leads</li>
                <li class="list-item">Target your segmented audiences</li>
              </ul>
            </div>
            </transition>

          <transition
            name="slide-left"
            mode="out-in">
            <div class="text-wrapper" v-if="content === 'partner' && lang === 'id'">
              <h4 id="text-title">Punya kafe atau resto? Ciptakan strategi pemasaran lewat data analitik</h4>
              <ul id="text-list">
                <li class="list-item">Pahami konsumen melalui real-time dashboard</li>
                <li class="list-item">Buat survei digital sesuai kebutuhan</li>
                <li class="list-item">Ciptakan interaksi lewat Gamification</li>
                <li class="list-item">Menangkan hati konsumen lewat Email & SMS Blast</li>
              </ul>
            </div>
          </transition>

          <transition
            name="slide-left"
            mode="out-in">
            <div class="text-wrapper" v-if="content === 'partner' && lang === 'en'">
              <h4 id="text-title">Do you own a cafe or restaurant? Create more effective marketing strategy with data analytics</h4>
              <ul id="text-list">
                <li class="list-item">Learn more about customers' insights through real-time dashboard</li>
                <li class="list-item">Understand your customer needs with digital surveys</li>
                <li class="list-item">Interact with your customers through Gamification</li>
                <li class="list-item">Win your customer's heart via Email & SMS Blast</li>
              </ul>
            </div>
          </transition>

          <transition
            name="slide-left"
            mode="out-in">
            <div class="text-wrapper" v-if="content === 'event' && lang === 'id'">
              <h4 id="text-title">Buat acara makin berkesan dengan fasilitas WiFi</h4>
              <ul id="text-list">
                <li class="list-item">Pilih ISP terbaik</li>
                <li class="list-item">Buat survei sesuai kebutuhan</li>
                <li class="list-item">Ciptakan activation kreatif dengan WiFi</li>
              </ul>
            </div>
          </transition>

          <transition
            name="slide-left"
            mode="out-in">
            <div class="text-wrapper" v-if="content === 'event' && lang === 'en'">
              <h4 id="text-title">Make unforgettable event with WiFi facility</h4>
              <ul id="text-list">
                <li class="list-item">Choose the right Internet Service Provider to create WiFi infrastructure</li>
                <li class="list-item">Create interactive survey according to your needs</li>
                <li class="list-item">Engage with the audience through creative WiFi activation</li>
              </ul>
            </div>
          </transition>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['lang'],
  data () {
    return {
      content: 'iklan',
      widthSelected: 30,
      leftSelected: 0,
      windowWidth: 0,
      loading: true,
      message: 'Ratusan Lokasi HIGOspot bukan hanya di Jabodetabek. Ada banyak lokasi HIGOspot di seluruh Indonesia yang bisa kamu cek di Google Maps. Mulai dari kafe yang lagi hits, co-working space, resto favorit dengan rating terbaik, mall terkenal, rumah sakit, taman, sekolah, hingga transportasi publik.      Manfaat Free WiFi HIGOspot: Kebebasan akses digitalisasi bagi semua orang, Menghemat kuota pengunjung, Memberikan kenyamanan saat berkunjung ke ruang publik.'
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  },
  watch: {
    lang (newVal) {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 550)
    }
  },
  methods: {
    imgLoad () {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    switchContent (val) {
      const num1 = 30
      const num2 = 40

      if (val === 'iklan') {
        if (this.content === 'partner') {
          this.widthSelected = num1
          this.leftSelected -= num1
        } else if (this.content === 'event') {
          this.leftSelected -= (num1 + num2)
        }
      }

      if (val === 'partner') {
        if (this.content === 'iklan') {
          // console.log('siiniel')
          this.widthSelected = num2
          this.leftSelected += num1
        } else if (this.content === 'event') {
          this.leftSelected -= (num2)
          this.widthSelected = num2
        }
      }

      if (val === 'event') {
        if (this.content === 'iklan') {
          this.widthSelected = num1
          this.leftSelected += (num1 + num2)
        } else if (this.content === 'partner') {
          this.leftSelected += num2
          this.widthSelected = num1
        }
      }
      // if (val === 'partner') {

      this.content = val
      // } else {
      //   this.widthSelected === '175px'
      // }
    }
  },
  created () {
    this.windowWidth = window.innerWidth
    this.widthSelected = 30
  }
}
</script>

<style scoped>
@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(1,254,254);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(1,254,254);
  }
}

@keyframes slideleft{

}

#container {
  width: 100%;
  height: 100vh;
  min-height: 680px;
  /* min-height: 600px; */
  overflow: hidden;
  background-color: rgb(0,67,171);
  padding-top: 6%;
}

#title {
  color: white;
  font-size: 1.8rem;
  width: 100%;
  text-align: center;
}

#select-content {
  width: 100%;
  margin-top: 1.2rem;
  display: grid;
  place-items: center;
}

#select-container {
  width: auto;
  height: auto;
  background-color: white;
  width: 580px;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  position: relative;
}

.select {
  /* background-color: white; */
  display: grid;
  place-items: center;
  border-radius: 20px;
  cursor: pointer;
  transition: .3s ease-in;
}

.select h4 {
  font-size: 0.9rem;
  z-index: 3;
}

.active {
  background-color: rgb(1,254,254);
}

.pulse {
  -webkit-animation: pulse 1s;
          animation: pulse 1s;
  box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
}

.select:nth-child(2), .select:nth-child(4) {
  width: 175px;
  height: 100%;
}

.select:nth-child(3) {
  width: 230px;
  height: 100%;
}

#selected {
  position: absolute;
  background-color: rgb(1,254,254);
  border-radius: 20px;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  transition: .3s ease-out;
  transition-timing-function: cubic-bezier(.51,.92,.24,1.15);
  box-shadow: 0px 0px 5px #0ff;
}

#main-content {
  width: 100%;
  min-height: 450px;
  height: 85%;
  display: grid;
  place-items: center;
  position: relative
}

#img-phone {
  position: absolute;
  width: 12rem;
  height: auto;
  left: 28%;
}

#wrapper {
  width: 50%;
  min-width: 450px;
  height: 65%;
  min-height: 300px;
  border-radius: 20px;
  background-color: white;
  position: relative;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.text-wrapper {
  width: 55%;
  height: 100%;
  margin-left: auto;
  padding: .5rem;
}

#text-title {
  color: rgb(0,67,171);
  font-size: 1rem;
  font-weight: 900;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.list-item {
  color: rgb(0,67,171);
  font-size: 0.9rem;
  margin-bottom: .5rem;
  margin-left: 1rem;
  /* margin: 1rem 1rem; */
}

#line-2 {
  position: absolute;
  width: 8rem;
  right: -3rem;
  bottom: -4%;
  z-index: 1;
}
@media screen and (min-width: 1200px) {
  #img-phone {
    width: 14rem;
    left: 10%;
    top: -1rem;
  }

  #wrapper {
    width: 800px;
    height: 400px;
  }

  .text-wrapper {
    width: 60%;
  }

  .list-item {
    margin-bottom: 1.6rem;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #wrapper {
    width: 700px;
    height: 340px;
  }

  #img-phone {
    left: 5%;
    top: -1rem;
  }

  .text-wrapper {
    width: 65%;
  }

  .list-item {
    margin-bottom: .8rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  #wrapper {
    width: 600px;
    height: 340px;
  }

  #img-phone {
    left: 5%;
    top: -1rem;
  }

  .text-wrapper {
    width: 62%;
  }

  .list-item {
    margin-bottom: .8rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  #wrapper {
    width: 560px;
    height: 300px;
  }

  #img-phone {
    left: 5%;
    top: -2.5rem;
  }

  .text-wrapper {
    width: 60%;
  }

  #text-title {
    margin: 1rem 0 1.5rem 0;
  }

  .list-item {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 575.98px) {
  #container {
    padding-top: 15%;
  }

  #main-content {
    min-height: 300px;
    height: 80%;
  }

  #title {
   font-size: 22px
  }

  .select h4 {
    font-size: 0.8rem;
  }

  #select-container {
    width: 90%;
    height: 2.5rem;
  }

  .select:nth-child(2), .select:nth-child(4) {
    width: 30%;
    height: 100%;
  }

  .select:nth-child(3) {
    width: 40%;
    height: 100%;
  }

  #wrapper {
    width: 90%;
    min-width: 0;
    height: 360px;
  }

  #img-phone {
    width: 40%;
    left: 5%;
    top: 14%;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  #text-title {
    margin-bottom: 1rem;
    font-size: .9rem;
    margin-left: .5rem;
    margin-top: 0;
  }

  .list-item {
    font-size: .8rem;
    margin-left: 1.5rem;
    font-weight: 500;
  }

  #line-2 {
    position: absolute;
    width: 8rem;
    right: -4.5rem;
    bottom: -10%;
  }
}

@media (max-width: 370px) and (max-height: 700px) {
  #text-title {
    font-size: .7rem;
    margin-left: .3rem;
  }

  .list-item {
    font-size: .7rem;
    margin-left: .7rem;
  }

  #line-2 {
    right: -5rem;
    bottom: -8%;
  }
}

@media (min-width: 1200px) {
  #title {
    font-size: min(2vw, 28px);
  }

  #text-title {
    font-size: min(1.2vw, 18px);
  }

  .list-item {
    font-size: min(1.2vw, 18px);
  }

  .select h4 {
    font-size: min(1.2vw, 18px);
  }
}
</style>
