<template>
  <header class="maincontent-container" :style="{getHeight}">
    <div v-if="lang === 'id'" class="text-container" style="padding-left:8%;">
      <h1 style="z-index: 99;margin-bottom:-70px;margin-top:50px">
        Di mana pun lokasinya
        <br />#ubahwifi jadi #smartwifi
      </h1>
      <!-- SEO => h1 became h2 -->
      <h2  style="font-size:24px">
        Mau maksimalin fasilitas WiFi di lokasimu?
      </h2>
      <div id="btn-home" style="margin-top:40px" @click.prevent="goContact">
        <p>Gabung bersama HIGOspot</p>
      </div>
    </div>
    <div v-else class="text-container" style="padding-left:8%;">
      <h2 style="z-index: 99;margin-bottom:-70px;margin-top:50px">
        Wherever you are
        <br />#changeyourwifi to #smartwifi
      </h2>
      <h2 style="font-size:24px">
        Do you want  to maximize the WiFi facility in your location?
      </h2>
      <div style="margin-top:40px" id="btn-home" @click.prevent="goContact">
        <p>Join <strong>HIGOspot</strong></p>
      </div>
    </div>
    <div style="display: none">
      Ratusan Lokasi HIGOspot bukan hanya di Jabodetabek. Ada banyak lokasi HIGOspot di seluruh Indonesia yang bisa kamu cek di Google Maps. Mulai dari kafe yang lagi hits, co-working space, resto favorit dengan rating terbaik, mall terkenal, rumah sakit, taman, sekolah, hingga transportasi publik.      Manfaat Free WiFi HIGOspot: Kebebasan akses digitalisasi bagi semua orang, Menghemat kuota pengunjung, Memberikan kenyamanan saat berkunjung ke ruang publik.
      Ratusan Lokasi HIGOspot bukan hanya di Jabodetabek. Ada banyak lokasi HIGOspot di seluruh Indonesia yang bisa kamu cek di Google Maps. Mulai dari kafe yang lagi hits, co-working space, resto favorit dengan rating terbaik, mall terkenal, rumah sakit, taman, sekolah, hingga transportasi publik.      Manfaat Free WiFi HIGOspot: Kebebasan akses digitalisasi bagi semua orang, Menghemat kuota pengunjung, Memberikan kenyamanan saat berkunjung ke ruang publik.
      Ratusan Lokasi HIGOspot bukan hanya di Jabodetabek. Ada banyak lokasi HIGOspot di seluruh Indonesia yang bisa kamu cek di Google Maps. Mulai dari kafe yang lagi hits, co-working space, resto favorit dengan rating terbaik, mall terkenal, rumah sakit, taman, sekolah, hingga transportasi publik.      Manfaat Free WiFi HIGOspot: Kebebasan akses digitalisasi bagi semua orang, Menghemat kuota pengunjung, Memberikan kenyamanan saat berkunjung ke ruang publik.
      Ratusan Lokasi HIGOspot bukan hanya di Jabodetabek. Ada banyak lokasi HIGOspot di seluruh Indonesia yang bisa kamu cek di Google Maps. Mulai dari kafe yang lagi hits, co-working space, resto favorit dengan rating terbaik, mall terkenal, rumah sakit, taman, sekolah, hingga transportasi publik.      Manfaat Free WiFi HIGOspot: Kebebasan akses digitalisasi bagi semua orang, Menghemat kuota pengunjung, Memberikan kenyamanan saat berkunjung ke ruang publik.
      Ratusan Lokasi HIGOspot bukan hanya di Jabodetabek. Ada banyak lokasi HIGOspot di seluruh Indonesia yang bisa kamu cek di Google Maps. Mulai dari kafe yang lagi hits, co-working space, resto favorit dengan rating terbaik, mall terkenal, rumah sakit, taman, sekolah, hingga transportasi publik.      Manfaat Free WiFi HIGOspot: Kebebasan akses digitalisasi bagi semua orang, Menghemat kuota pengunjung, Memberikan kenyamanan saat berkunjung ke ruang publik.
      Ratusan Lokasi HIGOspot bukan hanya di Jabodetabek. Ada banyak lokasi HIGOspot di seluruh Indonesia yang bisa kamu cek di Google Maps. Mulai dari kafe yang lagi hits, co-working space, resto favorit dengan rating terbaik, mall terkenal, rumah sakit, taman, sekolah, hingga transportasi publik.      Manfaat Free WiFi HIGOspot: Kebebasan akses digitalisasi bagi semua orang, Menghemat kuota pengunjung, Memberikan kenyamanan saat berkunjung ke ruang publik.
      Ratusan Lokasi HIGOspot bukan hanya di Jabodetabek. Ada banyak lokasi HIGOspot di seluruh Indonesia yang bisa kamu cek di Google Maps. Mulai dari kafe yang lagi hits, co-working space, resto favorit dengan rating terbaik, mall terkenal, rumah sakit, taman, sekolah, hingga transportasi publik.      Manfaat Free WiFi HIGOspot: Kebebasan akses digitalisasi bagi semua orang, Menghemat kuota pengunjung, Memberikan kenyamanan saat berkunjung ke ruang publik.
    </div>
  </header>
</template>

<script>
export default {
  props: ['lang'],
  data: function () {
    return {
      windowHeight: 0
    }
  },
  created () {
    this.windowHeight = window.innerHeight
  },
  computed: {
    getHeight () {
      return `minHeight: ${this.windowHeight}px`
    }
  },
  methods: {
    goContact () {
      sessionStorage.setItem('from-url-contact', window.location.href)
      this.$router.push('/contact-us')
    }
  }
}
</script>

<style>
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(4vh);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(4vh);
  }
  100% {
    transform: translateY(0);
  }
}

/* Pulse button animation */
@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(9,0,101);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(9,0,101);
  }
}

/* main content */
.maincontent-container {
  width: 100%;
  padding-top: 8vh;
  /* min-height: 725px; */
  min-height: 100vh;
  max-height: 100vh;
  /* min-height: 863px; */
  color: #fff;
  position: relative;
  overflow: hidden;
}

.maincontent-container h1, h2 {
  font-size: 38px;
  margin-bottom: 1rem;
  position: relative;
  z-index: 10 !important;
  padding-top: 5rem;
  font-size: "Akzidenz-regular";
  font-weight: 600;
}

.maincontent-container p {
  font-size: 20px;
  font-family: "Akzidenz-light";
   position: relative;
  z-index: 10;
}

#btn-home {
  max-width: 19rem;
  margin-top: 1rem;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255,212,0);
  color: rgb(9,0,101);
  cursor: pointer;
  z-index: 2;
  border-radius: 25px;
  --color: #ef6eae;
  --hover: #ef8f6e;
  transition: .3s ease-in-out;
}

button {
  color: var(--color);
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
#btn-home:hover, #btn-home:focus {
  /* border-color: var(--hover);
  color: #fff;
  background-color: rgb(9,0,101); */
}

#btn-home p{
  font-size: 16px;
  font-weight: 600;
}

#btn-home:hover {
  box-shadow: 0 0 10px rgb(255,212,0), 0 0 40px rgb(255,212,0), 0 0 80px rgb(255,212,0);
}

@media (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
  .text-container {
    width: 1140px;
    margin: 0 auto;
    z-index: 10;
  }

}

@media screen and (min-width: 1400px) and (max-width: 1556px) and (-webkit-min-device-pixel-ratio: 1) {
  .text-container {
    width: 1140px;
    margin: 0 auto;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1399px) and (-webkit-min-device-pixel-ratio: 1) {
  .text-container {
    width: 1140px;
    margin: 0 auto;
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .text-container {
    width: 960px;
    margin: 0 auto;
    padding-left: 10%;
  }

  .text-container h1, h2 {
    font-size: 38px;
  }
}

@media screen and (min-width: 884px) and (max-width: 991px) {
  .maincontent-container h1 {
    font-size: 35px;
  }

  .maincontent-container p {
    font-size: 18px;
  }
}

@media screen and (max-width: 575px) {
    .text-container {
    margin: 0 auto;
    width: 100%;
    padding: 0 2rem !important;

  }

  .text-container h1, h2 {
    margin-top: 1rem;
    font-size: 2rem;
    text-align: left;
    padding-top: 15%;
  }

  .text-container p {
    font-size: 1.2rem;
    text-align: left;
  }

  .maincontent-container {
    background-image: url('~@/assets/homepage/Path 1-min.png') ;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 2rem;
  }

  .backdrop-container {
    background-image: none !important;
  }

  #btn-home {
    width: 85%;
  }
}

@media screen and (max-width: 575px) and (min-height: 641px) {
  .text-container h1, h2 {
    margin-top: 8%;
  }
}
@media screen and (max-width: 575px) and (max-height: 640px) {
  .text-container h1, h2 {
    font-size: 2rem;
    margin-top: .8rem;
  }

  .text-container p {
    font-size: 1.2rem;
  }

  #btn-home {
    height: 2.5rem;
    margin-top: .8rem;
  }
  #btn-home p {
    font-size: .9rem;
  }
}

@media screen  and (max-width: 883px) and (min-width: 576px) and (max-height: 700px){
  .text-container {
    margin: 0 auto;
    margin-left: 25px;
  }

  .text-container h1, h2 {
    margin-top: 1rem;
    font-size: 35px;
  }

  .text-container p {
    font-size: 1.1rem;
  }

  .maincontent-container {
    background-image: url('~@/assets/homepage/Path 1-min.png') ;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .backdrop-container {
    background-image: none !important;
  }

  #btn-home {
    width: 15rem;
    height: 2.5rem;
  }

  #btn-home p {
    font-size: 13.5px;
  }
}

@media screen and (max-width: 883px) and (min-width: 576px) and (min-height: 700px) {
  .text-container {
    margin: 0 auto;
    margin-left: 25px;
  }

  .text-container h1, h2 {
    margin-top: 2rem;
    font-size: 35px;
  }

  .text-container p {
    font-size: 1.1rem;
  }

  .maincontent-container {
    background-image: url('~@/assets/homepage/Path 1-min.png') ;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .backdrop-container {
    background-image: none !important;
  }
}

@media screen and (max-height: 639px) and (max-width: 375px) {
  .text-container h1, h2 {
    font-size: 1.8rem;
    margin-top: 0;
    padding-top: 3.8rem
  }

  .text-container {
    padding: 0 1.5rem !important;
  }

  .text-container p {
    font-size: 1.2rem;
  }

  #btn-home {
    height: 2.3rem;
  }

  #btn-home p {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .text-container {
    width: 100%;
  }

}

@media (min-width: 1200px) {
  .maincontent-container h1, h2 {
    font-size: min(3vw, 48px);
  }

  .maincontent-container p {
    font-size: min(1.8vw, 24px);
  }

  .text-container {
    padding-left: 4rem !important;
  }
}
</style>
