<template>
  <div id="outer-container">
    <!-- <vue-element-loading :active="loading" is-full-screen>
      <img src="/static/pikachu.gif" width="55px" height="55px">
  </vue-element-loading> -->
    <AnnualReportModal />
    <AnnualReportFormModal :is-modal="true" />

    <div class="home-container">
      <!-- person 1 -->

      <div class="rotate">
        <div class="relative">
          <img
            @load="imgLoad"
            src="@/assets/homepage/Ring (1)-min.png"
            alt="wifi-panet"
            id="wifi-planet"
          />
          <img
            @load="imgLoad"
            src="@/assets/homepage/wifi-globe-min.png"
            alt="globe"
            id="wifi-globe"
          />
        </div>
      </div>

      <div class="person">
        <img
          @load="imgLoad"
          src="@/assets/homepage/person-1.png"
          alt="person-1"
          id="person-1"
        />
        <!-- person 2 -->
        <img
          @load="imgLoad"
          src="@/assets/homepage/person-2.png"
          alt="perosn-2"
          id="person-2"
        />
      </div>

      <img
        @load="imgLoad"
        src="@/assets/homepage/dot-1.png"
        v-if="windowWidth > 575"
        alt="dot_1"
        id="dot-1"
      />
      <img
        @load="imgLoad"
        src="@/assets/homepage/dot-2.png"
        v-if="windowWidth > 575"
        alt="dot-2"
        id="dot-2"
      />
      <!-- Navbar Component -->
      <div
        class="backdrop-container"
        :style="{ minHeight: windowHeight + 'px' }"
      >
        <Navbar @lang="getLang" color="white" />
        <!-- Content -->
        <FirstContent :lang="lang" />
      </div>

      <!-- location -->
      <ThirdContent :lang="lang" />

      <!-- Last Container -->
      <FinalContent :lang="lang" />

      <!-- Slider -->
      <SliderBottom />

      <!-- Footer -->
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AnnualReportModal from "@/components/AnnualReport2024/AnnualReportModal";
import AnnualReportFormModal from "@/components/AnnualReport2024/AnnualReportFormModal";
import Footer from "@/components/Footer";
import FinalContent from "@/components/Home/FinalContent.vue";
import FirstContent from "@/components/Home/FirstContent.vue";
import SliderBottom from "@/components/Home/SliderBottom.vue";
import ThirdContent from "@/components/Home/ThirdContent";
import Navbar from "@/components/Navbar";

export default {
  name: "home",
  components: {
    AnnualReportModal,
    AnnualReportFormModal,
    Navbar,
    FirstContent,
    ThirdContent,
    FinalContent,
    SliderBottom,
    Footer,
  },
  metaInfo: () => {
    return {
      title: "HIGO | WiFi Advertising Platform dengan Ratusan Spot",
      meta: [
        {
          name: "description",
          content:
            "WiFi Advertising & WiFi Marketing yang menjadi solusi bagi brand, lokasi partner dan event melakukan iklan, survey, digital marketing dengan data analitik.",
        },
        {
          name: "keywords",
          content:
            "WiFi Advertising Platform Indonesia, WiFi Marketing di Indonesia, WiFi Marketing Jakarta, WiFi Branding, WiFi Platform, WiFi Analitik, WiFi Analytic Jakarta, HIGO, WiFi Advertising Platform Jakarta, WiFi Platform Indonesia, WiFi Ads, WiFi Ads Indonesia, Iklan di WiFi, WiFi Ads Jakarta",
        },
      ],
    };
  },
  data: () => {
    return {
      windowHeight: 0,
      windowWidth: 0,
      load: 6,
      loading: false,
      lang: "",
    };
  },
  methods: {
    imgLoad() {
      this.load--;
    },
    getLang(value) {
      this.lang = value;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
    if (!sessionStorage.getItem("annual-report-2023")) {
      this.$modal.show("annual-report-modal");
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  },
};
</script>

<style scoped>
#outer-container {
  width: 100%;
  height: auto;
}

.home-container {
  width: 100%;
  min-width: 320px;
  /* height: auto; */
}

.backdrop-container {
  width: 100%;
  min-height: 580px;
  max-height: 100vh;
  background: url("~@/assets/homepage/backdrop-min.png") no-repeat;
  background-size: 100% 100%;
  z-index: -9999;
}

#person-1 {
  position: absolute;
  z-index: 4;
  width: 25%;
  height: auto;
  top: 15rem;
  right: 5rem;

  animation: float 3s ease-in-out 1.5s infinite;
  -webkit-animation: float 3s ease-in-out 1.5s infinite;
}

#person-2 {
  position: absolute;
  z-index: 4;
  width: 15%;
  height: auto;
  top: 18rem;
  right: 28rem;

  animation: float 3s ease-in-out 1.2s infinite;
  -webkit-animation: float 3s ease-in-out 1.2s infinite;
}

.rotate {
  position: absolute;
  top: 6rem;
  right: 16rem;
  width: 255.75px;
  height: 200px;
  z-index: 1;
  transform: rotate(12deg);
}

.relative {
  position: relative;
  width: 100%;
  height: 100%;
}

#wifi-planet {
  position: absolute;
  z-index: -20;
  max-width: 100% !important;
  height: 100%;
}

#wifi-globe {
  position: absolute;
  z-index: -25;
  max-width: 75% !important;
  height: 75%;
  top: 13%;
  left: 20%;

  animation: rotation 2.5s infinite linear;
  -webkit-animation: rotation 2.5s infinite linear;
}

#dot-1 {
  position: absolute;
  z-index: 1;
  width: 8%;
  height: auto;
  top: 3rem;
  left: 4rem;
}

#dot-2 {
  position: absolute;
  z-index: 1;
  width: 20%;
  height: auto;
  top: 20rem;
  left: -7rem;
}

@media screen and (max-width: 575px) {
  #dot-2 {
    top: 25rem;
    left: -5rem;
  }
}

@media screen and (max-width: 883px) and (min-width: 576px) {
  #dot-1 {
    top: 4rem;
  }

  #dot-2 {
    left: -4.5rem;
  }

  #person-1 {
    top: 66%;
    right: 2rem;
  }

  #person-2 {
    top: 78%;
    right: 18rem;
  }

  .rotate {
    width: 190px;
    height: 138px;
    top: 59%;
    right: 9rem;
  }
}

@media screen and (max-width: 991px) and (min-width: 884px) {
  .rotate {
    width: 200px;
    height: 157px;
    top: 8rem;
    right: 8rem;
  }

  #dot-1 {
    top: 4.2rem;
  }

  #dot-2 {
    top: 24rem;
    left: -6rem;
  }

  #person-1 {
    right: 2rem;
  }

  #person-2 {
    right: 18rem;
    top: 20rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  #dot-1 {
    width: 80px;
  }

  .rotate {
    top: 8.5rem;
    right: 11rem;
    width: 226.75px;
    height: 168px;
  }

  #dot-2 {
    top: 20rem;
    width: 210px;
    left: -7.3rem;
  }

  #person-1 {
    right: 3rem;
  }

  #rotate {
    width: 240.75px;
    height: 185px;
  }

  #person-2 {
    right: 22rem;
  }
}

@media screen and (min-height: 800px) and (max-width: 1199px) and (min-width: 992px) {
  #dot-2 {
    top: 32rem;
    width: 260px;
  }

  #person-2 {
    right: 25rem;
    top: 25rem;
    width: 13rem;
  }

  #person-1 {
    top: 20rem;
  }

  #rotate {
    top: 25rem;
  }
}

@media screen and (max-width: 575px) {
  #dot-1 {
    width: 65px;
    top: 9%;
  }

  #dot-2 {
    width: 180px;
    left: -20%;
  }

  #wifi-planet {
    width: 100%;
    height: auto;
  }

  #wifi-globe {
    width: 60%;
    height: auto;
    top: 1rem;
    left: 1.8rem;
  }

  .rotate {
    top: 60vh;
    left: 38vw;
    width: 170px;
    height: 165.39px;
    transform: rotate(-2deg);
  }

  #person-2 {
    width: 22%;
    top: 80vh;
    left: 35vw;
  }

  #person-1 {
    width: 40%;
    top: 75%;
    left: 58vw;
  }

  .tooltip .tooltiptext {
    left: -8vw;
  }
}

@media screen and (max-width: 575px) and (max-height: 700px) {
  #dot-2 {
    left: -28%;
    width: 160px;
  }
}

@media screen and (min-width: 1400px) {
  .rotate {
    top: 15rem;
    right: 20rem;
  }

  #person-2 {
    top: 26rem;
    right: 33rem;
    width: 248.58px;
    height: auto;
  }

  #person-1 {
    top: 20rem;
    right: 3rem;
  }

  #dot-2 {
    top: 30rem;
    left: -10rem;
  }
}

@media screen and (min-width: 1300px) and (min-height: 600px) {
  .rotate {
    top: 11rem;
    right: 23rem;
  }

  #person-2 {
    top: 25rem;
    right: 28rem;
  }
}

@media screen and (max-width: 575px) and (min-height: 600px) {
  #dot-2 {
    left: -25%;
    top: 31rem;
  }
}
</style>
