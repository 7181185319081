<template>
  <div id="container">
    <carousel style="width: 100%;" :autoplayTimeout="2000" :loop="true" :autoplay="true" :per-page="windowWidth < 767 ? 2 : 4" :touchDrag="true" :mouseDrag="true" :paginationEnabled="false">
      <slide v-for="(item, idx) in icons" :key="idx">
        <div class="icon-item">
          <img :src="item.image" :style="{height: item.height}" alt="">
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
export default {
  data () {
    return {
      windowWidth: 0,
      icons: [
        {
          image: require('@/assets/merchant/8th Brew Coffee Shop-min.png'),
          height: '3.8rem'
        },
        {
          image: require('@/assets/icon-free/Mask Group 22.png')
        },
        {
          image: require('@/assets/merchant/wills.png')
        },
        {
          image: require('@/assets/merchant/Banainai-min.png')
        },
        {
          image: require('@/assets/merchant/Kemenady-min.png')
        },
        {
          image: require('@/assets/icon-free/Mask Group 29.png')
        },
        {
          image: require('@/assets/icon-free/Mask Group 23.png')
        },
        {
          image: require('@/assets/merchant/Kintaro Sushi-min.png')
        }
      ]
    }
  },
  created () {
    this.windowWidth = window.innerWidth
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 150px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.icon-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding-left: 50%;
}

.icon-item img {
  height: 3rem;
}
</style>
