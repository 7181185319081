<template>
  <div class="location-container" style="z-index: 2; background-color: white;">

    <div style="margin:15rem auto; display: flex; flex-direction: column; font-style:italic; text-align:center; line-height: 300%; letter-spacing: 4px">
      <div style="font-size: 4rem; font-weight: bolder; padding-bottom: 35px; text-shadow: -1px 1px, 1px 0px, 1px 1px;"><strong>WiFi Advertising</strong></div>
      <div style="font-size: 4rem; font-weight: bolder; padding-bottom: 5rem; text-shadow: -1px 1px, 1px 0px, 1px 1px;">Platform Indonesia</div>
    </div>

    <div v-if="lang === 'id'" class="title">
      <h2 style="text-align:center; line-height: 120%">
        Lihat Lokasi HIGOspot
      </h2>
      <p>Ratusan Lokasi HIGOspot bukan hanya di Jabodetabek. Ada banyak lokasi HIGOspot di seluruh Indonesia yang bisa kamu cek di Google Maps. Mulai dari kafe yang lagi hits, co-working space, resto favorit dengan rating terbaik, mall terkenal, rumah sakit, taman, sekolah, hingga transportasi publik.</p>
    </div>

    <div v-else class="title">

      <h2 style="text-align:center; line-height: 120%">
        HIGOspot Location
      </h2>
      <p>Not only in Jabodetabek area, you can easily find HIGOspot locations throughout Indonesia in Google Maps. Our locations varies from popular cafes, co-working spaces, best rated restaurants, famous malls, hospitals, parks, schools to public transportation.</p>
    </div>

    <div class="content-location" v-if="windowWidth > 576">
      <div class="card">

        <button class="img-wrapper" @click="getMore()">
          <img v-if="lang === 'id'" src="@/assets/homepage/Group 373-min.png" alt="location-1" class="sacled" />
          <img v-else src="@/assets/homepage/Free-min.png" alt="location-1" class="sacled" />
        </button>
      </div>

      <div class="card">
        <button class="img-wrapper" @click="getMore()">
          <img v-if="lang === 'id'" src="@/assets/homepage/Group 374-min.png" alt="location-2" class="sacled" />
          <img v-else src="@/assets/homepage/Group_416-min.png" alt="location-2" class="sacled" />
        </button>
      </div>

      <div class="card">
        <button class="img-wrapper" @click="getMore()">
          <img v-if="lang === 'id'" src="@/assets/homepage/Group 375-min.png" alt="location-2" class="sacled" />
          <img v-else src="@/assets/homepage/School-min.png" alt="location-2" class="sacled" />
        </button>
      </div>
    </div>

    <carousel style="min-height: 52vh;" :loop="true"
              :items="3"
              :autoWidth="true"
              :center="true"
              :nav="false"
              :dots="false" v-else>
      <div style="width: 290px; height: auto;" class="slide-h3">

        <div class="img-wrapper img-wrapper-mobile" style="background: none; border: none; margin: 0 auto;">

          <img
          v-if="lang==='id'"
            @click="getMore()"
            src="@/assets/homepage/Group 373-min.png"
            alt="location-1"
            class="sacled"
            style="width: 70%; margin: 0 auto !important; height: auto;"
          />

          <img
          v-else
            @click="getMore()"
            src="@/assets/homepage/Free-min.png"
            alt="location-1"
            class="sacled"
            style="width: 70%; margin: 0 auto !important; height: auto;"
          />
        </div>
      </div>

      <div style="width: 290px; height: auto;" class="slide-h3">

        <div class="img-wrapper img-wrapper-mobile" style="background: none; border: none; margin: 0 auto;;">
          <img
            v-if="lang === 'id'"
            @click="getMore()"
            src="@/assets/homepage/Group 374-min.png"
            alt="location-2"
            class="sacled"
            style="width: 85%; margin: 0 auto !important; height: auto;"
          />

          <img
            v-else
            @click="getMore()"
            src="@/assets/homepage/Group_416-min.png"
            alt="location-2"
            class="sacled"
            style="width: 85%; margin: 0 auto !important; height: auto;"
          />
        </div>
      </div>

      <div style="width: 290px; height: auto;" class="slide-h3">

        <div class="img-wrapper img-wrapper-mobile" style="background: none; border: none; margin: 0 auto;">
          <img
            v-if="lang === 'id'"
            @click="getMore()"
            src="@/assets/homepage/Group 375-min.png"
            alt="location-2"
            class="sacled"
            style="width: 85%; margin: 0 auto !important; height: auto;"
          />
          <img
            v-else
            @click="getMore()"
            src="@/assets/homepage/School-min.png"
            alt="location-2"
            class="sacled"
            style="width: 85%; margin: 0 auto !important; height: auto;"
          />
        </div>
      </div>
    </carousel>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  props: ['lang'],
  components: { carousel },
  data: function () {
    return {
      windowWidth: 0,
      centerOption: {
        centerMode: true,
        slidesToShow: 1
      },
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 0,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  },
  methods: {
    getMore (value) {
      this.$router.push('/higospot')
    }
  },
  created () {
    this.windowWidth = window.innerWidth
  }
}
</script>

<style scpoed>
.location-container {
  max-width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  color: #0245ab;
  padding-top: 3.5rem;
}

.location-container .title {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.location-container .title h1, h2 {
  font-size: 1.8rem;
  font-weight: 700;
}

.location-container .title p {
  font-size: .9rem;
  text-align: center;
  max-width: 55rem;
  width: 85%;
  margin-top: 1.4rem;
  color: rgb(5 , 5, 5);
  line-height: 1.88;
}

.location-container .title h3 {
  font-size: 32px;
  font-weight: 650;
}

.location-container #dot-location {
  position: absolute;
  width: 300px;
  height: auto;
  right: -10vw;
  top: 2vh;
  z-index: -20;
}

.location-container .content-location {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.location-container .content-location img {
  width: 100px;
  height: auto;
  margin-top: 10px;
  z-index: 200;
}

.location-container .card {
  width: 400px;
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-location {
  display: flex;
  flex-wrap: wrap;

  height: auto;
}

.more-content {
  margin-top: 100px;
  width: 100%;
  min-height: 50vh;
}

.main-title {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-title h1 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 0 10px;
}

.main-title p {
  color: #0245ab;
  font-size: 1.2rem;
  margin-top: 10px;
}

.content-location button {
  border: none;
  outline: none;
  /* width: 100%; */
  height: 400px;
  background-color: transparent;
  transition: 0.3s ease-out;
}

.content-location button img {
  width: 160px;
  height: auto;
  transition: 0.3s ease-out
}

.content-location button:hover {
  cursor: pointer;
}

.partners {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}

.card h1 {
  font-size: 30px;
  margin-top: 10px;
  height: 50px;
  text-align: center;
}

.container-partners {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.container-partners img {
  opacity: 1;
  display: block;
  width: 70%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
/* transition */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#double-wiggle {
  position: absolute;
  width: 500px;
  height: auto;
  top: 28vh;
  left: -19vw;
  z-index: -10;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  margin: 0 auto; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  font-family: Arial, Helvetica, sans-serif;
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  margin: 1px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

#see-more {
  margin: 0 auto;
  border: none;
  border-radius: 50px;
  background-color: #0245ab;
  color: white;
  width: 15rem;
  height: 60px;
  font-size: 1.2rem;
  transition: 0.3s;
  padding: 10px 0;
  margin-bottom: 40px;
}

#see-more:hover {
  cursor: pointer;
  background-color: #f9ca45;
  color: #0245ab;
}

.morecontent-container {
  margin-top: 10px;
  height: 400px;
  transition: 0.5s ease-out;
}

.img-wrapper {
  display: grid;
  place-items: center;
  width: 280px;
  height: 400px;
  margin: 20px auto !important;
  transition: 0.3s ease-out
}

.img-wrapper:hover, .img-wrapper-mobile {
  border-radius: 45px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

@media screen and (max-width: 1200px) and (min-width: 991px) {
  #double-wiggle {
    width: 22%;
    left: -1.5vw;
    top: 60.5vh;
  }
}

@media screen and (max-width: 990px) {
  #dot-location {
    width: 30% !important;
    top: 0 !important;
  }

  #double-wiggle {
    width: 22%;
    left: 0.5vw;
    top: 60vh;
  }

  .morecontent-container {
    margin-top: 20px;
    min-height: 55vh;
  }

  .more-content {
    margin-top: 20px;
  }

  .main-title {
    text-align: center;
    height: auto;
  }

  .main-title h1 {
    font-size: 1rem;
  }

  .main-title p {
    font-size: 1rem;
  }

  .partners {
    padding: 0 20px !important;
    height: auto;
  }

  .partners .container-partners {
    width: 60px;
  }

  .partners img {
    width: 60px;
  }

  .location-container .title h1 {
    font-size: 2rem;
    font-weight: 700;
  }

  .location-container .content-location img {
    width: 280px;
    height: auto;
  }

  .partners .container-partners {
    margin: 15px;
    height: auto;
  }

  .more-content .main-title p {
    padding: 0 20px;
  }

  .partners {
    margin: 15px 0;
  }

  .morecontent-container {
    margin-top: 30px;
  }

  .card h1 {
    height: 10%;
  }

  .carousel-3d-container {
    height: 400px;
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .location-container .card {
    width: 330px;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .location-container .card {
    width: 230px;
  }

  .location-container .content-location img {
    width: 230px;
    height: auto;
  }

  .card h1 {
    font-size: 22px;
}
}

@media screen and (min-width: 992px) {
  .location-container .card {
    width: 300px;
  }

  .card h1 {
    font-size: 22px;
    height: 45px;
}

  .location-container .content-location img {
    width: 85%;
    height: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  .location-container .card {
    width: 250px;
  }

  .location-container .content-location img {
    width: 85%;
    height: auto;
  }

  .card h1 {
    font-size: 18px;
}
}

@media screen and (max-width: 575px) {
  .sacled {
    width: 90% !important;
  }

  .location-container {
    padding-top: 6.5rem;
  }

  .location-container .title h1 {
    font-size: 22px;
  }

  .location-container .title p {
    font-size: .8rem;
    text-align: justify;
    margin-bottom: 1rem;
  }
}

@media (min-width: 1200px) {
  .location-container .title h1 {
    font-size: min(2vw 28px);
  }

  .location-container .title p {
    font-size: min(1.2vw, 18px);
  }
}
</style>
